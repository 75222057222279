/**
 * Formats a given amount in the de-DE currency format
 *
 * @param {number} amount The given amount.
 * @returns {string} The formatted amount in the desired currency.
 */
const formatCurrency = (
  amount: number,
  options?: Record<string, number | string>
) => {
  return Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
    // check if whole number
    minimumFractionDigits: amount % 1 == 0 ? 0 : 2,
    ...options,
  }).format(amount)
}

const formatNumber = (amount: number) => {
  return Intl.NumberFormat('de-DE', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(amount)
}

function isJSON(str: string) {
  try {
    return JSON.parse(str) && !!str
  } catch (e) {
    return false
  }
}

const formatStringOnlyDigits = (value: string) => value.replace(/[^0-9]/g, '')

const formatBytes = (bytes: number, decimals = 2) => {
  if (!bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export {
  formatCurrency,
  formatNumber,
  formatStringOnlyDigits,
  isJSON,
  formatBytes,
}
